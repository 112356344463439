var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-main",
        [
          _c("form-layout", {
            ref: "formLayout",
            attrs: { column: _vm.optionChild.column, dataForm: _vm.dataObj },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }