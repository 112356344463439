var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("head-layout", {
        attrs: {
          "head-btn-options": _vm.headBtnOptions,
          "head-title":
            `[${_vm.dictValue}]` +
            _vm.$t("cip.plat.sys.dict.title.indexConfigHeadTitle"),
        },
        on: { "head-add": _vm.getNew, "head-romve": _vm.handleDelete },
      }),
      _c("grid-head-layout", {
        ref: "searchFrom",
        attrs: { "search-columns": _vm.searchColumns },
        on: {
          "grid-head-search": _vm.searchChange,
          "grid-head-empty": _vm.searchReset,
        },
        model: {
          value: _vm.searchForm,
          callback: function ($$v) {
            _vm.searchForm = $$v
          },
          expression: "searchForm",
        },
      }),
      _c("grid-layout", {
        ref: "gridLayOut",
        attrs: {
          tableOptions: _vm.optionChild,
          tableData: _vm.dataChild,
          "table-loading": _vm.loadingChild,
          page: _vm.pageChild,
          gridRowBtn: _vm.gridRowBtn,
        },
        on: {
          "gird-handle-select-click": _vm.selectionChange,
          "grid-edit": _vm.rowUpdate,
          "grid-romve": _vm.rowDel,
          "grid-addChild": _vm.handleAdd,
        },
      }),
      _vm.isShow
        ? _c(
            "CommonDialog",
            {
              attrs: { dialogTitle: "系统字典编辑", width: "60%" },
              on: {
                cancel: function ($event) {
                  _vm.isShow = false
                },
                confirm: _vm.rowDict,
              },
            },
            [
              _c("dict-config-edit", {
                ref: "dictConfigEdit",
                attrs: { dataObj: _vm.dataObj, tittle: _vm.dictValue },
                on: { cancel: _vm.cancel },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }