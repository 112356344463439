<template>
  <div>
    <head-layout :head-btn-options="headBtnOptions"
                 :head-title="`[${dictValue}]` + $t('cip.plat.sys.dict.title.indexConfigHeadTitle')"
                 @head-add="getNew"
                 @head-romve="handleDelete">
    </head-layout>
     <grid-head-layout ref="searchFrom"
                       :search-columns="searchColumns"
                       v-model="searchForm"
                       @grid-head-search="searchChange"
                       @grid-head-empty="searchReset">
    </grid-head-layout>
    <grid-layout ref="gridLayOut"
                 :tableOptions="optionChild"
                 :tableData="dataChild"
                 :table-loading="loadingChild"
                 :page="pageChild"
                 @gird-handle-select-click="selectionChange"
                 :gridRowBtn="gridRowBtn"
                 @grid-edit="rowUpdate"
                 @grid-romve="rowDel"
                 @grid-addChild="handleAdd">

    </grid-layout>
    <CommonDialog
        v-if="isShow"
        dialogTitle="系统字典编辑"
        width="60%"
        @cancel="isShow = false"
        @confirm="rowDict"
    >
      <dict-config-edit
          ref="dictConfigEdit"
          :dataObj="dataObj"
          @cancel="cancel"
          :tittle="dictValue"
      ></dict-config-edit>
    </CommonDialog>
  </div>
</template>

<script>
import {
  getParentList,
  getChildList,
  remove,
  update,
  add,
  getDict,
  getDictTree
} from "@/api/system/dict";
import { mapGetters } from "vuex";
import { optionParent, optionChild } from "@/option/system/dict";
import HeadLayout from "@/views/components/layout/head-layout"
import GridLayout from "@/views/components/layout/grid-layout";
import dialogHeadBtn from "../components/layout/dialog-head-btn";
import formLayout from '@/views/components/layout/form-layout'
import dictConfigEdit from '@/views/system/dictConfigEdit.vue'
import CommonDialog from "@/components/CommonDialog/index.vue";
import dictEdit from "@/views/system/dictEdit.vue";

export default {
  components: {
    dictEdit,
    CommonDialog,
    HeadLayout,
    GridLayout,
    dialogHeadBtn,
    formLayout,
    dictConfigEdit
  },
  data() {
    return {
      isShow:false,
      formData:{},
      dataObj: '',
      searchForm: {},
      gridRowBtn: [
        {
          label: this.$t('cip.cmn.btn.editBtn'),
          emit: "grid-edit",
          type: "text",
          icon: ""
        },
         {
          label: this.$t('cip.cmn.btn.delBtn'),
          emit: "grid-romve",
          type: "text",
          icon: ""
        },
        {
          label: this.$t('cip.cmn.btn.addChildBtn'),
          emit: "grid-addChild",
          type: "text",
          icon: ""
        }
      ],
      searchColumns: [
        {
          label: "",
          prop: "code",
          search: true,
          span: 4,
          placeholder: this.$t("cip.cmn.rule.inputWarning") + this.$t('cip.plat.sys.dict.field.code'),

        },

        {
          label: "",
          placeholder: this.$t("cip.cmn.rule.inputWarning") + this.$t('cip.plat.sys.dict.field.dictValue'),
          span: 4,
          prop: "dictValue",
          search: true,
        },
      ],
      headBtnOptions: [{
        label: this.$t('cip.cmn.btn.addBtn'),
        emit: "head-add",
        type: "button",
        icon: ""
      }, {
        label: this.$t('cip.cmn.btn.delBtn'),
        emit: "head-romve",
        type: "button",
        icon: ""
      }, ],
      loadingChild: true,
      pageChild: {
        pageSize: 10,
        pageSizes: [10, 20,30, 40, 50, 100],
        currentPage: 1,
        total: 0
      },
      dictValue:'',
      parentId: '-1',
      addChildParentId: '',
      dataChild: [],
      formChild: {},
      selectionList: [],
      optionChild: optionChild(this),
    }
  },
  computed: {
    ...mapGetters(["userInfo", "permission"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.dict_add, false),
        delBtn: this.vaildData(this.permission.dict_delete, false),
        editBtn: this.vaildData(this.permission.dict_edit, false),
        viewBtn: false,
      };
    },
    ids() {
      let ids = [];
      this.selectionList.forEach(ele => {
        ids.push(ele.id);
      });
      return ids.join(",");
    }
  },
  watch:{
    formData:function (){
      this.data = JSON.parse(decodeURIComponent(this.formData))
      this.parentId = this.data.id
      this.dictValue = this.data.dictValue;

      const code = this.findObject(this.optionChild.column, "code");
      code.value = this.data.code;
      const parentId = this.findObject(this.optionChild.column, "parentId");
      parentId.value = this.data.id;
      this.onLoadChild(this.pageChild)
    }
  },
  created() {
    this.findObject(this.optionChild.column, "code").disabled = true;
    this.findObject(this.optionChild.column, "parentId").disabled = true;
    this.optionChild.viewBtn = false
    this.optionChild.searchShow = false
    this.onLoadChild(this.pageChild)
  },
  mounted() {
    this.initData();
  },
  methods: {
    getNew() {
      if(this.parentId==-1){
        this.$message({
          type: "warning",
          message:'请选择字典！'
        });
        return;
      }
      this.dataObj = {
        parentId:this.data.id,
        code:this.data.code
      };
      this.isShow = true;
    },
    save() {
      this.$refs.formLayout.$refs.form.validate(valid => {
        if (valid) {
          let obj = this.$refs.formLayout.dataForm
          obj.parentId = this.addChildParentId
          let requestType = obj.hasOwnProperty('id') ? update : add
          requestType(obj).then(() => {
            this.onLoadChild(this.pageChild);
            this.dataObj = ''
            this.$message({
              type: "success",
              message: this.$t("cip.cmn.msg.success.operateSuccess")
            });
            // done();
          }, error => {
            window.console.log(error);

            this.$refs.formLayout.$refs.form.allDisabled = false
          });
        } else {
          this.$refs.formLayout.$refs.form.allDisabled = false
        }
      })

    },
    cancel() {
      this.isShow = false;
      this.dataObj = {};
      this.onLoadChild(this.pageChild);
    },
    initData() {
      getDictTree().then(res => {
        const column = this.findObject(this.optionChild.column, "parentId");
        column.dicData = res.data.data;
      });
    },
    rowDict(){
      this.$refs.dictConfigEdit.headSave('save');
    },
    handleAdd(row) {
      this.dataObj = {
        parentId:row.id,
        code:row.code
      };
      this.isShow = true;
    },
    rowSave(row, done, loading) {
      const form = {
        ...row,
        dictKey: -1,
      };
      add(form).then(() => {
        this.onLoadChild(this.pageChild);
        this.$message({
          type: "success",
          message: this.$t("cip.cmn.msg.success.operateSuccess")
        });
        // done();
      }, error => {
        window.console.log(error);
        loading();
      });
    },
    rowUpdate(row) {
      this.isShow=true
      this.dataObj = row
    },
    rowDel(row) {
      this.$confirm(this.$t('cip.cmn.msg.warning.delWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return remove(row.id);
        })
        .then(() => {
          this.onLoadChild(this.pageChild);
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess")
          });
        });
    },
    handleRowClick(row) {
      this.$router.push({
        path: '/dict/config',
        query: {
          formData: encodeURIComponent(JSON.stringify(row))
        }
      })
    },
    searchReset() {
      this.query = {};
      this.onLoadChild(this.pageChild);
    },
    searchChange(params, done) {
      this.query = params;
      this.pageChild.currentPage = 1;
      this.onLoadChild(this.pageChild, params);
      // done();
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    selectionClear() {
      this.selectionList = [];
      //  this.$refs.gridLayOut.selectionClear();
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    handleDelete() {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.selectWarning'));
        return;
      }
      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return remove(this.ids);
        })
        .then(() => {
          this.onLoadChild(this.pageChild);
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess")
          });
          this.$refs.gridLayOut.selectionClear();
        });
    },
    beforeOpen(done, type) {
      if (["edit", "view"].includes(type)) {
        getDict(this.formParent.id).then(res => {
          this.formParent = res.data.data;
        });
      }
      // done();
    },
    currentChange(currentPage) {
      this.pageChild.currentPage = currentPage;
    },
    sizeChange(pageSize) {
      this.pageChild.pageSize = pageSize;
    },
    refreshChange() {
      this.onLoadChild(this.pageChild, this.query);
    },
    rowSaveChild(row, done, loading) {
      add(row).then(() => {
        this.onLoadChild(this.pageChild);
        this.$message({
          type: "success",
          message: this.$t("cip.cmn.msg.success.operateSuccess")
        });
        // done();
      }, error => {
        window.console.log(error);
        loading();
      });
    },
    rowUpdateChild(row, index, done, loading) {
      update(row).then(() => {
        this.onLoadChild(this.pageChild);
        this.$message({
          type: "success",
          message: this.$t("cip.cmn.msg.success.operateSuccess")
        });
        // done();
      }, error => {
        window.console.log(error);
        loading();
      });
    },
    rowDelChild(row) {
      this.$confirm(this.$t('cip.cmn.msg.warning.delWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return remove(row.id);
        })
        .then(() => {
          this.onLoadChild(this.pageChild);
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess")
          });
        });
    },
    searchResetChild() {
      this.query = {};
      this.onLoadChild(this.pageChild);
    },
    searchChangeChild(params, done) {
      this.query = params;
      this.pageChild.currentPage = 1;
      this.onLoadChild(this.pageChild, params);
      // done();
    },
    selectionChangeChild(list) {
      this.selectionList = list;
    },
    selectionClearChild() {
      this.selectionList = [];
      this.$refs.crudChild.toggleSelection();
    },
    handleDeleteChild() {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.selectWarning'));
        return;
      }
      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return remove(this.ids);
        })
        .then(() => {
          this.onLoadChild(this.pageChild);
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess")
          });
          this.$refs.crudChild.toggleSelection();
        });
    },
    beforeOpenChild(done, type) {
      if (["add", "edit"].includes(type)) {
        this.initData();
      }
      if (["edit", "view"].includes(type)) {
        getDict(this.formChild.id).then(res => {
          this.formChild = res.data.data;
        });
      }
      // done();
    },
    beforeCloseChild(done) {
      this.$refs.crudChild.value.parentId = this.parentId;
      this.$refs.crudChild.option.column.filter(item => {
        if (item.prop === "parentId") {
          item.value = this.parentId;
        }
      });
      // done();
    },
    currentChangeChild(currentPage) {
      this.pageChild.currentPage = currentPage;
    },
    sizeChangeChild(pageSize) {
      this.pageChild.pageSize = pageSize;
    },
    refreshChangeChild() {
      this.onLoadChild(this.pageChild, this.query);
    },

    onLoadChild(page, params = {}) {
      this.loadingChild = true;
      getChildList(
        page.currentPage,
        page.pageSize,
        this.parentId,
        Object.assign(params, this.query)
      ).then(res => {
        this.dataChild = res.data.data;
        //树形表不用分页
        // this.dataTotal = this.dataChild.length
        this.loadingChild = false;
        this.selectionClear();
        this.$refs.gridLayOut.page.total =Math.floor(Math.random() * 100) + 1;
      });
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .avue-crud__pagination{
  display: none;
}
</style>
